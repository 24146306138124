import { Formik, FormikErrors, FormikHelpers } from 'formik';
import { get, isNumber, reduce } from 'lodash';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BareInput, { NumberInput } from '../../components/form/BareInput';
import { Step, Wizard } from '../../components/wizard';
import { ErrorLevel, useAddError, useClearError } from '../../context/error';
import { EmptyNewExaminee, Examinee, NewExaminee } from '../../interfaces/Examinee';
import { ExamineeFamiliesService, ExamineesService, UserService } from '../../services';

import styled from 'styled-components';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import ExamineeAdditionalInformationSection from '../../components/common/examinee/ExamineeAdditionalInformationSection';
import ExamineeFamilySelect from '../../components/common/examinee/ExamineeFamilySelect';
import ExamineeTypeSelect from '../../components/common/examinee/ExamineeTypeSelect';
import InstallationSiteSelect from '../../components/common/examinee/InstallationSiteSelect';
import VendorSelect from '../../components/common/examinee/VendorSelect';
import FormikDebug from '../../components/dev/formik-debug';
import InputGroup from '../../components/form/InputGroup';
import { useMain } from '../../context/main';
import validateExamineeTagFree from '../../helpers/validate/validateExamineeTagFree';
import { ExamineeFamily } from '../../interfaces/ExamineeFamily';
import { examineeFamilyStore } from '../../storage';
import DeviceTagSelector from './DeviceTagSelector';

const P = styled.p<{ small: boolean }>`
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 0.75rem;
  color: ${(props) => (props.small ? '#b3b3b3' : '#000')};
  min-height: 18px;
`;

type FormType = NewExaminee & {
  family: number;
  additionalInformation?: Record<string, undefined | null | string | Array<string>>;
  intervals: { testTask: number; interval: number }[];
};

const Row100 = styled(Row)`
  width: 100%;
`;

const MCol = styled(Col)`
   {
    margin-bottom: 1rem;
    span {
      width: 100%;
      display: block;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      appearance: none;
      border-radius: 0.25rem;
    }
  }
`;

const NewModalStepInterval: FunctionComponent<{
  familyID: number;
  errors: FormikErrors<FormType>;
  isSubmitting: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}> = ({ setFieldValue, familyID, errors, isSubmitting }) => {
  const addError = useAddError();

  const [loading, setLoading] = useState(true);

  const [family, setFamily] = useState<ExamineeFamily>();

  const loadExamineeFamily = useCallback(async () => {
    const item = await ExamineeFamiliesService(addError, examineeFamilyStore).get(familyID.toString());
    setFamily(item);
    setFieldValue(`intervals`, [], true);
    item?.testTasks.forEach((testTask, index) => {
      setFieldValue(`intervals[${index}].testTask`, testTask.id, true);
      setFieldValue(`intervals[${index}].interval`, testTask.defaultInterval, true);
    });
  }, [addError, familyID, setFieldValue]);

  useEffect(() => {
    const loadData = async () => {
      try {
        loadExamineeFamily();
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [addError, loadExamineeFamily]);

  if (loading) {
    return <LoadingIndicator size={40} />;
  }

  return (
    <>
      {family?.testTasks.map((testTask, index) => (
        <InputGroup
          key={`intervals[${index}]`}
          variant="gray-200"
          title={`${testTask.initials}-Intervall`}
          name={`intervals[${index}]`}
        >
          <input type="hidden" name={`intervals[${index}].testTask`} />
          <NumberInput float={false} disabled={isSubmitting} name={`intervals[${index}].interval`} min={0} />
        </InputGroup>
      ))}
    </>
  );
};

const NewModal: FunctionComponent = () => {
  const addError = useAddError();
  const clearError = useClearError();

  const navigate = useNavigate();
  const [search] = useSearchParams();

  // const [loading, setLoading] = useState(true);

  // selected test location
  const { selectedLocation } = useMain();

  // const [tag, setTag] = useState<string | undefined | null>();
  const [examinee, setExaminee] = useState<Examinee>();
  const [examineeLocked, setExamineeLocked] = useState<boolean>();
  const [searching, setSearching] = useState<boolean>();

  // useEffect(() => {
  //   auth.loadUser().then(() => {
  //     setLoading(false);
  //   });
  // }, [auth]);

  const [step, setStep] = useState(0);

  const onSubmit = async (values: FormType, { setSubmitting }: FormikHelpers<FormType>) => {
    setSubmitting(true);
    clearError();
    try {
      const additionalInformation = reduce<Record<number, string>, Record<string, string>>(
        values.additionalInformation,
        (prev, acc, key) => {
          if (acc) {
            prev[key.toString()] = acc;
          }
          return prev;
        },
        {},
      );
      const id = await ExamineesService(addError).create({
        ...values,
        additionalInformation,
      });

      await UserService(addError).setPreference('examinee.create.family', values.family);
      await UserService(addError).setPreference(
        'examinee.create.intervals',
        JSON.stringify(values.intervals.map((i) => ({ testTask: i.testTask, interval: i.interval }))),
      );
      await UserService(addError).setPreference(
        'examinee.create.installationSiteDescription',
        values.installationSiteDescription,
      );
      await UserService(addError).setPreference('examinee.create.installationSite', values.installationSite);

      if (id && typeof id === 'number' && (id > 0 || id < 0)) {
        navigate(`/tests/new/${id}`);
      }
      // else if (id && typeof id === 'number' && id < -1) {
      //   navigate(`/tests/new-offline/${id}`);
      //   // navigate(`/offline/`);
      // }
    } catch (error) {
      addError({
        level: ErrorLevel.danger,
        message: get(error, 'message', 'Unbekannter Fehler'),
      });
    } finally {
      setSubmitting(false);
    }
  };

  const validate = async (values: FormType) => {
    const errors: any = {};

    if (!values.tag) {
      errors['tag'] = true;
    } else {
      const r = await validateExamineeTagFree(values.tag)
        .then(() => null)
        .catch((e) => 'Die ID konnte nicht überprüft werden.');
      if (r !== null) {
        errors['tag'] = true;
      }
    }

    if (values.installationSite < 1) {
      errors['installationSite'] = true;
    }

    if (values.vendor < 1) {
      errors['vendor'] = true;
    }

    if (!values.family || values.family === 0) {
      errors['family'] = true;
    }

    if (values.type < 1) {
      errors['type'] = true;
    }

    if (values.intervals) {
      const intervalErrors: any = {};
      values.intervals.forEach((interval, index) => {
        if (interval.interval === undefined || interval.interval === null || interval.interval < 0) {
          intervalErrors[index] = true;
        }
      });
      if (Object.keys(intervalErrors).length > 0) {
        errors['intervals'] = intervalErrors;
      }
    }

    return errors;
  };

  const [initialValues, setInitialValues] = useState<FormType | undefined>(undefined);

  useEffect(() => {
    const load = async () => {
      const [family, intervals, installationSiteDescription, installationSite] = await Promise.all([
        UserService(addError).getPreference<number>('examinee.create.family'),
        UserService(addError).getPreference<string>('examinee.create.intervals'),
        UserService(addError).getPreference<string>('examinee.create.installationSiteDescription'),
        UserService(addError).getPreference<number>('examinee.create.installationSite'),
      ]);
      setInitialValues({
        ...EmptyNewExaminee,
        tag: search.get('tag') ?? '',
        location: selectedLocation && selectedLocation.length > 0 ? selectedLocation[0].value : 0,
        family: family && isNumber(Number(family)) ? Number(family) : 0,
        installationSite: installationSite && isNumber(Number(installationSite)) ? Number(installationSite) : 0,
        intervals: JSON.parse(intervals ?? '[]'),
        installationSiteDescription,
      });
    };
    load();
  }, [addError, search, selectedLocation]);

  if (!initialValues) {
    return <LoadingIndicator size={40} />;
  }

  return (
    <Modal size="lg" centered show={true}>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...initialValues,
        }}
        onSubmit={onSubmit}
        validate={validate}
      >
        {({ errors, isSubmitting, touched, values, setFieldValue, submitForm, isValidating, validateForm }) => (
          <>
            <form
              onKeyDown={async (e) => {
                if (e.key === 'Enter') {
                  const err = await validateForm(values);
                  if (!err || Object.keys(err).length === 0) {
                    setStep(step + 1);
                  }
                }
              }}
            >
              <Wizard
                showHeader={false}
                step={step}
                preparing={false}
                saveText={values.tag && !examinee ? 'Erstellen & Prüfen' : 'Prüfen'}
                onChange={(s) => {
                  setStep(s);
                }}
                onClose={() => navigate('/')}
                onComplete={() => {
                  if (examinee) {
                    navigate(`/tests/new/${examinee.id}`);
                  } else {
                    submitForm();
                  }
                }}
              >
                <Step
                  title="Eine neue Prüfung durchführen"
                  description="Prüfling auswählen"
                  valid={!isValidating && !searching && !examineeLocked}
                >
                  <p>Bitte geben Sie die ID des Prüfling oder eine neue ID ein.</p>
                  <DeviceTagSelector
                    selected={values.location}
                    onSearching={(v) => setSearching(v)}
                    onInputChanged={(value: string) => {
                      setFieldValue('tag', value);
                    }}
                    onExamineeChanged={(scan: boolean, locked: boolean, examinee: Examinee | undefined) => {
                      setExaminee(examinee);
                      setExamineeLocked(locked);
                      if (!locked && scan) {
                        setStep(1);
                      }
                    }}
                  />
                </Step>
                {/* More steps to create a new examinee */}
                {values.tag && !examinee && (
                  <Step
                    title="Einen neuen Prüfling anlegen"
                    description="Prüfling anlegen"
                    valid={!isValidating && !errors.tag && !errors.family && !errors.type}
                  >
                    <InputGroup valid={true} title="Prüfling-ID" name="tag" variant="gray-200">
                      <BareInput type="text" name="tag" value={values.tag} disabled={true} />
                    </InputGroup>
                    <ExamineeFamilySelect
                      variant="gray-200"
                      onValueSelected={() => {
                        setFieldValue('type', 0);
                      }}
                      editing={true}
                    />
                    <ExamineeTypeSelect variant="gray-200" editing={true} />
                  </Step>
                )}
                {/* {values.tag && !examinee && (
                  <Step
                    title="Von welchem Hersteller ist der Prüfling?"
                    description="Hersteller"
                    valid={!errors.vendor && touched.vendor}
                  ></Step>
                )} */}
                {values.tag && !examinee && (
                  <Step
                    title="Daten zum Prüfling"
                    description="Hersteller & Aufstellort"
                    valid={!isValidating && !errors.installationSite && !errors.vendor && !errors.intervals}
                  >
                    <VendorSelect variant="gray-200" editing={true} />
                    <InstallationSiteSelect variant="gray-200" editing={true} />
                    <InputGroup
                      variant="gray-200"
                      valid={!errors.installationSiteDescription}
                      title="Bezeichnung"
                      name="installationSiteDescription"
                    >
                      <BareInput disabled={isSubmitting} type="text" name="installationSiteDescription" />
                    </InputGroup>
                    <NewModalStepInterval
                      setFieldValue={setFieldValue}
                      familyID={values.family}
                      errors={errors}
                      isSubmitting={isSubmitting}
                    />
                  </Step>
                )}
                {values.tag && !examinee && (
                  <Step
                    title="Weitere Informationen"
                    description=""
                    valid={
                      !isValidating &&
                      ((!errors.additionalInformation && touched.additionalInformation) ||
                        (Array.isArray(values.additionalInformation) && values.additionalInformation.length === 0))
                    }
                  >
                    <ExamineeAdditionalInformationSection variant="gray-200" editing={true} />
                  </Step>
                )}
                {values.tag && !examinee && (
                  <Step notCloseable={true} title="Prüfling wird gespeichert..." description="" valid={false}>
                    <LoadingIndicator size={40} />
                  </Step>
                )}
                {examinee && (
                  <Step title="ausgewählter Prüfling" description="" valid={true}>
                    <Row100>
                      <MCol xs={12} lg={6}>
                        <span>
                          <P small={false}>{examinee.tag}</P>
                          <P small={true}>
                            {examinee.type.name} - {examinee.type.family.name}
                          </P>
                        </span>
                      </MCol>
                      <MCol xs={12} lg={6}>
                        <span>
                          <P small={false}>{`[${examinee.location.tag}] ${examinee.location.name}`}</P>
                          <P small={true}>
                            {`${examinee.location.address.street}, ${examinee.location.address.postalCode} ${examinee.location.address.city}`}
                          </P>
                        </span>
                      </MCol>
                      <MCol xs={12} lg={6}>
                        <span>
                          <P small={false}>{get(examinee, 'installationSite.name', '')}</P>
                          <P small={true}>{examinee.installationSiteDescription ?? 'Keine Beschreibung'}</P>
                        </span>
                      </MCol>
                      <MCol xs={12} lg={6}>
                        <span>
                          <P small={false}>{examinee?.vendor?.name ?? 'Keine Infomation'}</P>
                          <P small={true}>
                            <br />
                          </P>
                        </span>
                      </MCol>
                    </Row100>
                  </Step>
                )}
                {examinee && <Step title="Ready" description="" valid={true}></Step>}
              </Wizard>
            </form>
            <FormikDebug />
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default NewModal;
